/* src/App.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

header {
  background-color: #333;
  color: white;
  padding: 1rem;
  text-align: center;
}

header nav ul {
  list-style: none;
  padding: 0;
}

header nav ul li {
  display: inline;
  margin: 0 1rem;
}

header nav ul li a {
  color: white;
  text-decoration: none;
}

.tool-list {
  display: flex;
  justify-content: space-around;
  padding: 2rem;
}

.tool {
  border: 1px solid #ddd;
  padding: 1rem;
  width: 200px;
  text-align: center;
}

footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  width: 100%;
}
